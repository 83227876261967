import oucy from "@/util/oucyUtil";
import { notice, stock, schemeOrder, furnitureGlobalBrand } from "@/service/index"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Enter",
    data() {
        return {}
    },
    computed: {},
    mounted() {},
    methods: {
        exportExcel(url){
 
               let a = document.createElement("a");
               // a.download = `${new Date().toLocaleString()}.xlsx`;
               a.setAttribute('href',url)
               a.click(); 
       
        },
        open111(url){
            window.open(url,'_blank')
        },
    }
}