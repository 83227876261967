<template>
    <div class="text-left bgimg">
        <div class="topnavs">
            <div class="topnav">
                <img class="logo" @click="$oucy.go('/', null)" src="@/assets/images/download/logo.png" alt="">
                <div class="navs">
                    <div class="nav" @click="$oucy.go('/', null)">首页</div>
                    <div class="nav" @click="$oucy.go('/furniture', null)">家具库</div>
                    <div class="nav" @click="$oucy.go('/material', null)">材料库</div>
                    <div class="nav" @click="$oucy.go('/company', null)">企业库</div>
                    <div class="nav" @click="$oucy.go('/card', null)">名片库</div>
                    <div class="nav" @click="$oucy.go('/askBuyList', null)">求购库</div>
                </div>
            </div>
        </div>
        <div class="container">
            <!-- <img src="@/assets/images/download/bg.png" alt="" class="bg"> -->
            <div class="center">
                <img src="@/assets/images/download/leftBg.png" class="leftBg" alt="">
                <div class="centerRight">
                    <div class="title m-t-35">乐搜家 <span>v1.1.3</span></div>
                    <div class="title2 m-t-20">家居供应链服务平台 <span>-全新上线-</span></div>
                    <div class="btns df m-t-80">
                        <div class="btn df-ai-c" @click="open111('https://h5.lesoujia.com/app.html')"><img src="@/assets/images/download/Android.png" alt="" class="icon"><img src="@/assets/images/download/Android_.png" alt="" class="icon_">Android下载</div>
                        <div class="btn m-l-20 df-ai-c" @click="open111('https://apps.apple.com/cn/app/%E4%B9%90%E6%90%9C%E5%AE%B6/id1595225322')"><img src="@/assets/images/download/Ios.png" alt="" class="icon"><img src="@/assets/images/download/Ios_.png" alt="" class="icon_">iPhone下载</div>
                    </div>
                    <div class="df  m-t-20 f24 text-center">
                        <div><img src="@/assets/images/download/mini.png" alt="" class="sao">
                            <div class="saotext">小程序</div>
                        </div>
                        <div class="m-l-20"><img src="@/assets/images/download/Official.png" alt="" class="sao">
                            <div class="saotext">公众号</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div style="text-align: center">
                <span>
                    乐搜家 © 2021-2022
                    ICP备案号：<a href="https://beian.miit.gov.cn/" target="_blank"> 粤ICP备17157497号 </a>
                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44060402002291" target="_blank"> <img style="height:15px;width:15px;" src="@/assets/icon/gaba.png">粤公网安备44060402002291号</a> <br>《中华人民共和国增值电信业务经营许可证》编号：<a href="https://aliyunoss.lecong1688.com/aljut/20210806/1628219091751.png" target="_blank">粤B2-20210912</a>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import download from './download.js'
export default download
</script>
<style lang="less" scoped>
@import "download.less";
</style>